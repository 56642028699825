import { useEffect, useState } from "react";
import apiCodeWeb from "../../utils/apiCodeWeb";
import CardInfo from "../../components/backend/CardInfo";
import { IoBriefcaseOutline, IoPersonCircleOutline } from "react-icons/io5";
import { BsChatSquareDots } from "react-icons/bs";
import { TbBriefcaseOff } from "react-icons/tb";

export interface Response {
    cant_user: number;
    portafolio_web: number;
    portafolio_inactivo: number;
    mensaje_sin_leer: number;
    mensaje_leidos: number;
}

const IndexPage = () => {

    const [response, setResponse] = useState<Response>({} as Response);

    useEffect(() => {
        obtenerDatos();
    }, []);

    const obtenerDatos = async () => {
        try {
            const { data } = await apiCodeWeb.get<Response>('/admin');
            // console.log(data);
            setResponse(data);
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <div className="space-y-2 mx-auto">
            {/* <!-- cabecera --> */}
            <div className="flex justify-between items-center bg-white py-2 px-3 border-b border-gray-200">
                <h1 className="text-xl font-bold">Panel de Resumen</h1>
                {/* <!-- boton --> */}
                <div className=""></div>
            </div>

            <div className="p-2 lg:p-4">
                <div className="flex flex-wrap">
                    <CardInfo icon={<IoPersonCircleOutline className="text-white text-2xl" />} path="/dashboard/usuarios" title="Cant Usuarios" cant={response.cant_user} />
                    <CardInfo icon={<BsChatSquareDots className="text-white text-2xl" />} path="/dashboard/mensajes" title="Cant Mensajes" cant={response.mensaje_sin_leer} />
                    <CardInfo icon={<IoBriefcaseOutline className="text-white text-2xl" />} path="/dashboard/portafolio" title="Cant Portafolio en Web" cant={response.portafolio_web} />
                    <CardInfo icon={<TbBriefcaseOff className="text-white text-2xl" />} path="/dashboard/portafolio" title="Cant Portafolio sin mostrar" cant={response.portafolio_inactivo} />
                </div>
            </div>
        </div>
    )
}

export default IndexPage