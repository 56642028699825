import { useEffect, Suspense, lazy } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import { routesDashboard } from "./dashboard/Routes";
import useAuthStore from "../context/authStore";


// Lazy loading de componentes
const ErrorPage = lazy(() => import("../pages/error/ErrorPage"));
const HomeLayout = lazy(() => import("../layout/HomeLayout"));
const IndexPage = lazy(() => import("../pages/home/IndexPage"));
const PrivateRouteDasboard = lazy(() => import("./dashboard/PrivateRouteDasboard"));
const DashboardLayout = lazy(() => import("../layout/DashboardLayout"));
const LoginPage = lazy(() => import("../pages/auth/LoginPage"));
const ServiciosPage = lazy(() => import("../pages/home/ServiciosPage"));
const PortafolioPage = lazy(() => import("../pages/home/PortafolioPage"));
const ContactoPage = lazy(() => import("../pages/home/ContactoPage"));

// Componente de loading para Suspense
const LoadingSpinner = () => (
    <div className="flex items-center justify-center min-h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
    </div>
);

// Crear el helmetContext
const helmetContext = {};

const router = createBrowserRouter([
    {
        path: '/',
        element: <Suspense fallback={<LoadingSpinner />}><HomeLayout /></Suspense>,
        children: [
            {
                index: true,
                element: <Suspense fallback={<LoadingSpinner />}><IndexPage /></Suspense>,
            },
            {
                path: '/servicios',
                element: <Suspense fallback={<LoadingSpinner />}><ServiciosPage /></Suspense>,
            },
            {
                path: '/portafolio',
                element: <Suspense fallback={<LoadingSpinner />}><PortafolioPage /></Suspense>,
            },
            {
                path: '/contacto',
                element: <Suspense fallback={<LoadingSpinner />}><ContactoPage /></Suspense>,
            },
            {
                path: '/login',
                element: <Suspense fallback={<LoadingSpinner />}><LoginPage /></Suspense>,
            },
        ]
    },
    {
        path: '/dashboard',
        element: (
            <Suspense fallback={<LoadingSpinner />}>
                <PrivateRouteDasboard>
                    <DashboardLayout />
                </PrivateRouteDasboard>
            </Suspense>
        ),
        children: [
            ...routesDashboard.map(route => ({
                path: route.path,
                element: route.element
            })),
            ...routesDashboard.flatMap(route =>
                route.subMenuItems
                    ? route.subMenuItems.map(subRoute => ({
                        path: subRoute.path,
                        element: subRoute.element
                    }))
                    : []
            )
        ],
    },
    {
        path: "*",
        element: (
            <Suspense fallback={<LoadingSpinner />}>
                <ErrorPage />
            </Suspense>
        )
    }
]);

const Router = () => {
    const checkAuth = useAuthStore(state => state.checkAuth);

    useEffect(() => {
        checkAuth(); // Validar autenticación al cargar la aplicación
    }, [checkAuth]);

    return (
        <HelmetProvider context={helmetContext}>
            <RouterProvider router={router} />
        </HelmetProvider>
    )
}

export default Router