import { useEffect, useState } from "react";
import apiCodeWeb from "../../utils/apiCodeWeb";
import { FiCalendar, FiEye, FiMail, FiMessageSquare, FiPhone, FiUser } from "react-icons/fi";
import DataTable from "../../components/datatable/DataTable";
import Modal from "../../components/modal/Modal";

interface ResponseMensaje {
    id: number;
    nombre: string;
    correo: string;
    celular: number;
    mensaje: string;
    estado: number;
    created_at: Date;
    updated_at: Date;
}

interface dataTableProps {
    N: number;
    nombre: string;
    estado: React.ReactNode;
    fecha: Date;
    action: React.ReactNode;
}

const headers = {
    N: 'N°',
    nombre: 'Nombre',
    estado: 'Estado',
    fecha: 'F.Envio',
    action: 'Acciones'
}

const formatDate = (date: Date) => {
    if (!date) return '';
    return new Date(date).toLocaleDateString('es-ES', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit'
    });
};

const MensajePage = () => {

    const [modal, setModal] = useState<boolean>(false);
    const [dataTable, setDataTable] = useState<dataTableProps[]>([]);
    const [mensaje, setMensaje] = useState<ResponseMensaje>({} as ResponseMensaje);


    useEffect(() => {
        obtenerMensajes();
    }, [])

    const obtenerMensajes = async () => {
        try {
            const { data } = await apiCodeWeb.get<ResponseMensaje[]>('/admin/mensaje');

            const dataTable = data.map((item, index) => ({
                N: index + 1,
                nombre: item.nombre,
                estado: item.estado ? (<span className="badge-sm badge-danger cursor-pointer">Sin Leer</span>) : (<span className="badge-sm badge-success cursor-pointer">Leido</span>),
                fecha: item.created_at,
                action: (<div className="flex gap-2">
                    <button onClick={() => openModalMensaje(item.id)} className="btn-sm btn-warning"><FiEye /></button>
                </div>),
            }))

            setDataTable(dataTable);

        } catch (error) {
            console.log(error);
        }
    }

    // MODALES
    const verDatoModal = () => {
        setModal(true);
    }

    const closeModal = () => {
        setModal(false);
    }

    // OBTENER DATOS PARA EDITAR
    const openModalMensaje = async (id: number) => {
        try {
            const { data } = await apiCodeWeb.get<ResponseMensaje>(`/admin/mensaje/${id}/show`);
            setMensaje(data);
            verDatoModal();
            handleEstado(data.id);
        } catch (error) {
            console.log(error);
        }
    }

    const handleEstado = async (id: number) => {
        try {
            const { data } = await apiCodeWeb.put(`/admin/mensaje/${id}/upestado`);
            console.log(data);
            obtenerMensajes();
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <div className="bg-[#F7F7F7] overflow-fix h-[calc(100vh-3rem)]">
            {/* <!-- cabecera --> */}
            <div className="flex justify-between items-center bg-white py-2 px-3 border-b border-gray-200">
                <h1 className="text-xl font-bold">Panel de Mensajes</h1>
                {/* <!-- boton --> */}
                <div className="">
                    {/* <button onClick={openModalCreate} className="btn-sm btn-primary" id="btnRegistrar">Agregar Usuario</button> */}
                </div>
            </div>

            <div className="mt-4 px-2">
                <DataTable data={dataTable} headers={headers} rowsPerPageOptions={[15, 25, 35]} />
            </div>

            {/* MODAL    */}
            <Modal title="Mensaje" show={modal} closeModal={closeModal} size="large">
                {/* Content section */}
                <div className="px-4 py-2 space-y-4">
                    {/* Sender Info Section */}
                    <div className="space-y-4">
                        {/* Name */}
                        <div className="flex items-center space-x-3">
                            <div className="flex-shrink-0">
                                <FiUser className="w-5 h-5 text-blue-500" />
                            </div>
                            <div className="flex gap-5 justify-center items-center">
                                <p className="text-sm text-gray-500">Nombre</p>
                                <p className="text-base font-medium text-gray-900">{mensaje.nombre}</p>
                            </div>
                        </div>

                        {/* Phone */}
                        <div className="flex items-center space-x-3">
                            <div className="flex-shrink-0">
                                <FiPhone className="w-5 h-5 text-blue-500" />
                            </div>
                            <div className="flex gap-5 justify-center items-center">
                                <p className="text-sm text-gray-500">Teléfono</p>
                                <p className="text-base font-medium text-gray-900">{mensaje.celular}</p>
                            </div>
                        </div>

                        {/* Email */}
                        <div className="flex items-center space-x-3">
                            <div className="flex-shrink-0">
                                <FiMail className="w-5 h-5 text-blue-500" />
                            </div>
                            <div className="flex gap-5 justify-center items-center">
                                <p className="text-sm text-gray-500">Email</p>
                                <p className="text-base font-medium text-gray-900">{mensaje.correo}</p>
                            </div>
                        </div>

                        {/* Date */}
                        <div className="flex items-center space-x-3">
                            <div className="flex-shrink-0">
                                <FiCalendar className="w-5 h-5 text-blue-500" />
                            </div>
                            <div className="flex gap-5 justify-center items-center">
                                <p className="text-sm text-gray-500">Fecha de envío</p>
                                <p className="text-base font-medium text-gray-900">
                                    {formatDate(mensaje.created_at)}
                                </p>
                            </div>
                        </div>
                    </div>

                    {/* Message Content Section */}
                    <div className="pt-2">
                        <div className="flex items-center space-x-3 mb-3">
                            <FiMessageSquare className="w-5 h-5 text-blue-500" />
                            <h4 className="text-lg font-medium text-gray-900">Mensaje</h4>
                        </div>
                        <div className="bg-gray-50 rounded-lg p-2 min-h-[100px] text-left">
                            <p className="text-gray-700 whitespace-pre-wrap">{mensaje.mensaje}</p>
                        </div>
                    </div>
                </div>

                {/* <!--Footer--> */}
                <div className="flex justify-end pt-2 mt-3">
                    <button onClick={closeModal} type="button" className="modal-close btn btn-danger">Cerrar</button>
                </div>
            </Modal>
        </div>
    )
}

export default MensajePage