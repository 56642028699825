import { useEffect, useState } from "react";
import useForm from "../../hooks/useForm";
import { useFormErrors } from "../../hooks/useFormErrors";
import apiCodeWeb from "../../utils/apiCodeWeb";
import DataTable from "../../components/datatable/DataTable";
import Modal from "../../components/modal/Modal";
import Input from "../../components/basic/Input";
import { HiOutlineUser } from "react-icons/hi";
import { IoKeyOutline } from "react-icons/io5";
import { FaEdit } from "react-icons/fa";
import { RiDeleteBinLine } from "react-icons/ri";
import errorResponse from "../../utils/errorResponse";
import ToastEfect from "../../components/toast/ToastEfect";
import Swal from "../../components/alert/Alert";


interface ResponseUser {
    id: number;
    username: string;
    estado: number;
    created_at: Date;
    updated_at: Date;
    token: null | string;
}

interface dataTableProps {
    N: number;
    username: string;
    estado: React.ReactNode;
    ultimoLogin: Date;
    action: React.ReactNode;
}

const headers = {
    N: 'N°',
    username: 'Usuario',
    estado: 'Estado',
    ultimoLogin: 'Ultimo Login',
    action: 'Acciones'
}

interface FormErrors {
    [key: string]: string | undefined;
    username?: string;
    password?: string;
}

const UserPage = () => {

    const [modal, setModal] = useState<boolean>(false);
    const [tipoModal, setTipoModal] = useState<string>('');
    const [dataTable, setDataTable] = useState<dataTableProps[]>([]);
    const [idUser, setIdUser] = useState(0);

    const { values, handleChange, setFormValues, resetForm } = useForm({
        username: '',
        password: '',
    })

    const { errors, setErrors, resetErrors } = useFormErrors<FormErrors>();

    useEffect(() => {
        obtenerUsuarios();
    }, [])


    const obtenerUsuarios = async () => {
        try {
            const { data } = await apiCodeWeb.get<ResponseUser[]>('/admin/user');

            const dataTable = data.map((item, index) => ({
                N: index + 1,
                username: item.username,
                estado: item.estado ? (<span onClick={() => handleEstado(item.id)} className="badge-sm badge-success cursor-pointer">Habilitado</span>) : (<span onClick={() => handleEstado(item.id)} className="badge-sm badge-danger cursor-pointer">Inhabilitado</span>),
                ultimoLogin: item.updated_at,
                action: (<div className="flex gap-2">
                    <button onClick={() => datosEditar(item.id)} className="btn-sm btn-primary" title="Editar"><FaEdit /></button>
                    <button onClick={() => handleDelete(item.id)} className="btn-sm btn-danger" title="Eliminar"><RiDeleteBinLine /></button>
                </div>),
            }))

            setDataTable(dataTable);

        } catch (error) {
            console.log(error);
        }
    }

    const openModalCreate = () => {
        setTipoModal('Registrar');
        setModal(true);
    }

    const openModalEdit = () => {
        setTipoModal('Editar');
        setModal(true);
    }

    const closeModal = () => {
        setTipoModal('');
        resetForm();
        resetErrors();
        setModal(false);
    }

    const datosEditar = async (id: number) => {
        try {
            const { data } = await apiCodeWeb.get(`/admin/user/${id}/edit`);
            setFormValues({ username: data.username });
            setIdUser(data.id);
            openModalEdit();
        } catch (error) {
            console.log(error);
        }
    }

    const handleEstado = async (id: number) => {
        Swal.fire({
            icon: "warning",
            title: "¿Estas seguro?",
            buttonConfirm: "si",
            buttonCancel: "no",
        }).then(async (result) => {
            if (result) {
                try {
                    const { data } = await apiCodeWeb.put(`/admin/user/${id}/upestado`);
                    ToastEfect({ type: 'success', message: data.message });
                    obtenerUsuarios();
                } catch (error) {
                    console.log(error);
                }
            }
        });
    }

    const hadleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        resetErrors();

        if (!values.username || !values.password) {
            setErrors({
                username: !values.username ? 'El campo usuario es requerido' : undefined,
                password: !values.password ? 'El campo contraseña es requerido' : undefined
            });
            return;
        }

        if (tipoModal === 'Registrar') {
            sendRequest('post', '/admin/user/create', values);
        } else {
            sendRequest('put', `/admin/user/${idUser}/update`, values);
        }
    }

    const sendRequest = async (method: 'post' | 'put', url: string, values: any) => {
        try {
            const { data } = await apiCodeWeb[method](url, values);
            closeModal();
            obtenerUsuarios();
            ToastEfect({ type: 'success', message: data.message });
        } catch (error: any) {
            errorResponse(error.response.data, 'username', setErrors);
        }
    };

    const handleDelete = async (id: number) => {
        Swal.fire({
            icon: "warning",
            title: "¿Estas seguro?",
            text: "Esta acción no se puede revertir",
            buttonConfirm: "si",
            buttonCancel: "no",
        }).then(async (result) => {
            if (result) {
                try {
                    const { data } = await apiCodeWeb.delete(`/admin/user/${id}/delete`);
                    ToastEfect({ type: 'success', message: data.message });
                    obtenerUsuarios();
                } catch (error) {
                    console.log(error);
                }
            }
        });
    }

    return (
        <div className="bg-[#F7F7F7] overflow-fix h-[calc(100vh-3rem)]">
            <div className="space-y-2 mx-auto">
                {/* <!-- cabecera --> */}
                <div className="flex justify-between items-center bg-white py-2 px-3 border-b border-gray-200">
                    <h1 className="text-xl font-bold">Panel de Usuarios</h1>
                    {/* <!-- boton --> */}
                    <div className="">
                        <button onClick={openModalCreate} className="btn-sm btn-primary" id="btnRegistrar">Agregar Usuario</button>
                    </div>
                </div>
                {/* <!-- cuerpo --> */}
                <div className="p-2 lg:p-4">
                    {/* <!-- tabla --> */}
                    <div className="card p-2 bg-white">
                        <div id="simpleDatatable"></div>
                    </div>
                </div>
            </div>

            <div className="">
                <DataTable data={dataTable} headers={headers} rowsPerPageOptions={[15, 25, 35]} />
            </div>

            {/* MODAL    */}
            <Modal title={tipoModal} show={modal} closeModal={closeModal}>
                <form onSubmit={hadleSubmit} className="w-full text-left">
                    {/* <!--Body--> */}
                    <div className="space-y-4">
                        <Input
                            name="username"
                            type="text"
                            label="Usuario"
                            value={values.username}
                            onChange={handleChange}
                            placeholder="Nombre de Usuario"
                            icon={<HiOutlineUser />}
                            error={errors.username}
                        />
                        <Input
                            name="password"
                            type="password"
                            label="Contraseña"
                            value={values.password}
                            onChange={handleChange}
                            placeholder="Tu Contraseña"
                            icon={<IoKeyOutline />}
                            error={errors.password}
                        />
                    </div>
                    {/* <!--Footer--> */}
                    <div className="flex justify-between mt-8">
                        <button type="button" onClick={closeModal} className="btn btn-danger">Cerrar</button>
                        <button type="submit" className="btn btn-success" id="btnSubmit">{tipoModal}</button>
                    </div>
                </form>
            </Modal>
        </div>
    )
}

export default UserPage