import { create } from 'zustand';
import { getValue, removeValue, storeValue } from '../utils/loscalStorage';
import apiCodeWeb from '../utils/apiCodeWeb';
import { ResponseLogin } from '../types/userInterface';

export interface ErrorMessage {
    username?: string;
    password?: string;
}

interface AuthState {
    logged: boolean;
    token: string | null;
    error: string | null;
    checkAuth: () => Promise<void>;
    login: (username: string, password: string) => Promise<void>;
    logout: () => void;
}

const useAuthStore = create<AuthState>()((set) => ({
    logged: false,
    token: null,
    // user: null,
    error: null,

    checkAuth: async () => {
        const storedToken = getValue('tokencodeweb');
        if (!storedToken) {
            set({ logged: false, token: null, });
            return;
        }

        try {
            const { data } = await apiCodeWeb.post<ResponseLogin>('/verify-token');

            if (data.status === 'success') {
                set({ error: null, logged: true });
            } else {
                set({ logged: false, token: null, });
                removeValue('tokencodeweb');
            }
        } catch (error) {
            set({ logged: false, token: null, });
            removeValue('tokencodeweb');
        }
    },

    login: async (username, password) => {
        try {
            const { data } = await apiCodeWeb.post<ResponseLogin>('/login', { username, password });
            if (data.status === 'success') {
                set({ token: data.token, error: null, logged: true });
                storeValue('tokencodeweb', data.token);
            }
        } catch (error: any) {
            console.log(error.response.data);
            const err = error.response.data;
            const msg = err?.errors.username || err?.errors.password || 'Error desconocido';
            set({ error: msg, token: null, logged: false });
        }
    },

    logout: async () => {
        set({ token: null, error: null, logged: false });
        removeValue('tokencodeweb');
    },

}))

export default useAuthStore