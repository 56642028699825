import IndexPage from "../../pages/dashboard/IndexPage";
import { MenuItem } from "../../types/DashboardInterface";
import UserPage from "../../pages/dashboard/UserPage";
import MensajePage from "../../pages/dashboard/MensajePage";
import PortafolioPage from "../../pages/dashboard/PortafolioPage";
import { MdOutlineDashboard } from "react-icons/md";
import { FaRegUser } from "react-icons/fa";
import { BiMessage } from "react-icons/bi";
import { GoBriefcase } from "react-icons/go";

export const routesDashboard: MenuItem[] = [
    {
        path: "/dashboard",
        name: 'Dashboard',
        element: <IndexPage />,
        icon: <MdOutlineDashboard className="item-icon" />,
    },
    {
        path: "/dashboard/usuarios",
        name: 'Usuarios',
        element: <UserPage />,
        icon: <FaRegUser className="item-icon" />,
    },
    {
        path: "/dashboard/mensajes",
        name: 'Mensajes',
        element: <MensajePage />,
        icon: <BiMessage className="item-icon" />,
    },
    {
        path: "/dashboard/portafolio",
        name: 'Portafolio',
        element: <PortafolioPage />,
        icon: <GoBriefcase className="item-icon" />,
    },
];