import { useEffect, useState } from "react";
import useForm from "../../hooks/useForm";
import { useFormErrors } from "../../hooks/useFormErrors";
import apiCodeWeb from "../../utils/apiCodeWeb";
import ToastEfect from "../../components/toast/ToastEfect";
import errorResponse from "../../utils/errorResponse";
import Swal from "../../components/alert/Alert";
import { FaEdit } from "react-icons/fa";
import DataTable from "../../components/datatable/DataTable";
import Modal from "../../components/modal/Modal";
import Input from "../../components/basic/Input";
import { MdOutlineTextSnippet, MdTitle } from "react-icons/md";
import { GoLink } from "react-icons/go";
import TextArea from "../../components/basic/TextArea";
import ImgUploader from "../../components/imageUpload/ImgUploader";

export interface ResponsePortafolio {
    id: number;
    title: string;
    content: string;
    photo: string;
    url: string;
    estado: number;
    created_at: Date;
    updated_at: string;
}

interface dataTableProps {
    N: number;
    title: string;
    estado: React.ReactNode;
    content: string;
    photo: React.ReactNode;
    fecha: Date;
    action: React.ReactNode;
}

const headers = {
    N: 'N°',
    title: 'Título',
    estado: 'Estado',
    content: 'Contenido',
    photo: 'Imagen',
    fecha: 'F.Creación',
    action: 'Acciones'
}

interface FormErrors {
    [key: string]: string | undefined;
    title?: string;
    url?: string;
    content?: string;
    photo?: string;
}
const PortafolioPage = () => {

    const [modal, setModal] = useState<boolean>(false);
    const [tipoModal, setTipoModal] = useState<string>('');
    const [dataTable, setDataTable] = useState<dataTableProps[]>([]);
    const [idPortafolio, setIdPortafolio] = useState(0);

    const [imagen, setImagen] = useState<File | null>(null);
    const [imagenUrl, setImagenUrl] = useState<string | null>(null);

    const { values, handleChange, setFormValues, resetForm } = useForm({
        title: '',
        url: '',
        content: '',
    })

    const { errors, setErrors, resetErrors } = useFormErrors<FormErrors>();

    useEffect(() => {
        (imagen) ? setImagenUrl(URL.createObjectURL(imagen)) : setImagenUrl(null);
    }, [imagen]);

    useEffect(() => {
        obtenerPortafolios();
    }, [])

    // OBTENER DATOS PARA EL DATATABLE
    const obtenerPortafolios = async () => {
        try {
            const { data } = await apiCodeWeb.get<ResponsePortafolio[]>('/admin/portafolio');

            const dataTable = data.map((item, index) => ({
                N: index + 1,
                title: item.title,
                estado: item.estado ? (<span onClick={() => handleEstado(item.id)} className="badge-sm badge-success cursor-pointer">En Web</span>) : (<span onClick={() => handleEstado(item.id)} className="badge-sm badge-danger cursor-pointer">En Espera</span>),
                content: item.content,
                photo: (<img className="w-12" src={item.photo} alt="portafolio" />),
                fecha: item.created_at,
                action: (<div className="flex gap-2">
                    <button onClick={() => datosEditar(item.id)} className="btn-sm btn-warning"><FaEdit /></button>
                    {/* <button onClick={() => handleDelete(item.id)} className="btn-sm btn-danger"><RiDeleteBinLine /></button> */}
                </div>),
            }))

            setDataTable(dataTable);
        } catch (error) {
            console.log(error);
        }
    }

    // MODALES
    const openModalCreate = () => {
        setTipoModal('Registrar');
        setModal(true);
    }

    const openModalEdit = () => {
        setTipoModal('Editar');
        setModal(true);
    }

    const closeModal = () => {
        setTipoModal('');
        resetForm();
        resetErrors();
        setModal(false);
        setImagenUrl(null);
        setImagen(null);
    }

    // OBTENER DATOS PARA EDITAR
    const datosEditar = async (id: number) => {
        try {
            const { data } = await apiCodeWeb.get(`/admin/portafolio/${id}/edit`);
            setFormValues({ title: data.title, url: data.url, content: data.content });
            setIdPortafolio(data.id);
            setImagenUrl(data.photo);
            openModalEdit();
        } catch (error) {
            console.log(error);
        }
    }

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        resetErrors();

        if (!values.title || !values.url || !values.content) {
            setErrors({
                title: !values.title ? 'El campo título es requerido' : undefined,
                url: !values.url ? 'El campo url es requerido' : undefined,
                content: !values.content ? 'El campo contenido es requerido' : undefined,
            });
            return;
        }

        const formData = new FormData();
        formData.append('title', values.title);
        formData.append('url', values.url);
        formData.append('content', values.content);

        if (tipoModal === 'Registrar') {
            // validar imagen
            if (!imagen) {
                setErrors({ photo: 'La imagen es obligatoria' });
                return;
            }
            formData.append('photo', imagen);

            sendRequest('post', '/admin/portafolio/create', formData);
        } else {
            if (imagen !== null) formData.append('photo', imagen);
            sendRequest('put', `/admin/portafolio/${idPortafolio}/update`, formData);
        }
    }


    const sendRequest = async (method: 'post' | 'put', url: string, values: any) => {
        try {
            const { data } = await apiCodeWeb[method](url, values, { headers: { 'Content-Type': 'multipart/form-data' } });
            closeModal();
            obtenerPortafolios();
            ToastEfect({ type: 'success', message: data.message });
        } catch (error: any) {
            errorResponse(error.response.data, 'title', setErrors);
        }
    };

    const handleEstado = async (id: number) => {
        Swal.fire({
            icon: "warning",
            title: "¿Estas seguro?",
            buttonConfirm: "si",
            buttonCancel: "no",
        }).then(async (result) => {
            if (result) {
                try {
                    const { data } = await apiCodeWeb.put(`/admin/portafolio/${id}/upestado`);
                    ToastEfect({ type: 'success', message: data.message });
                    obtenerPortafolios();
                } catch (error) {
                    console.log(error);
                }
            }
        });
    }

    return (
        <div className="bg-[#F7F7F7] overflow-fix h-[calc(100vh-3rem)]">
            {/* <!-- cabecera --> */}
            <div className="flex justify-between items-center bg-white py-2 px-3 border-b border-gray-200">
                <h1 className="text-xl font-bold">Panel de Trabajos</h1>
                {/* <!-- boton --> */}
                <div className="">
                    <button onClick={openModalCreate} className="btn-sm btn-primary" id="btnRegistrar">Agregar Portafolio</button>
                </div>
            </div>

            <div className="px-2 mt-4">
                <DataTable data={dataTable} headers={headers} rowsPerPageOptions={[15, 25, 35]} />
            </div>

            {/* MODAL    */}
            <Modal title={tipoModal} show={modal} closeModal={closeModal} >
                <form onSubmit={handleSubmit} className=" text-left">
                    {/* <!--Body--> */}
                    <div className="space-y-4 min-w-96">
                        <Input
                            name="title"
                            type="text"
                            label="Título"
                            value={values.title}
                            onChange={handleChange}
                            placeholder="Nombre de Usuario"
                            icon={<MdTitle />}
                            error={errors.title}
                        />
                        <Input
                            name="url"
                            type='text'
                            label="Url Web"
                            value={values.url}
                            onChange={handleChange}
                            placeholder="Tu Contraseña"
                            icon={<GoLink />}
                            error={errors.url}
                        />
                        <TextArea
                            name="content"
                            label="Descripción"
                            value={values.content}
                            onChange={handleChange}
                            placeholder="Descripción del Portafolio"
                            icon={<MdOutlineTextSnippet />}
                            error={errors.content}
                        />
                        <ImgUploader
                            setImagenFile={setImagen}
                            imagenUrl={imagenUrl}
                            setErrors={setErrors}
                            error={errors.photo}
                            fieldName="photo"
                        />
                    </div>
                    {/* <!--Footer--> */}
                    <div className="flex justify-between mt-8">
                        <button type="button" onClick={closeModal} className="btn btn-danger">Cerrar</button>
                        <button type="submit" className="btn btn-success" id="btnSubmit">{tipoModal}</button>
                    </div>
                </form>
            </Modal>
        </div>
    )
}

export default PortafolioPage