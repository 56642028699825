import { Link } from "react-router-dom";

interface CardInfoProps {
    icon: React.ReactNode;
    path: string;
    title: string;
    cant: number | string;
}

const CardInfo = ({ icon, path, title, cant }: CardInfoProps) => {
    return (
        <div className="w-full sm:w-1/2 lg:w-1/3 p-2">
            <div className="bg-white rounded-lg shadow-lg p-4">
                <div className="flex items-center">
                    <div className="p-3 rounded-full bg-slate-600 bg-opacity-75">
                        {icon}
                    </div>
                    <div className="ml-3">
                        <Link to={path} className="text-gray-700 font-semibold hover:text-red-400">{title}</Link>
                        <h3 className="text-2xl font-semibold text-gray-800" id="usuarios">{cant}</h3>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CardInfo